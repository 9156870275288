import { FacebookSvgBlack } from '~/components/social-svgs/facebook-svg-black';
import { InstagramSvgBlack } from '~/components/social-svgs/instagram-svg-black';
import { YouTubeSvgBlack } from '~/components/social-svgs/youtube-svg-black';
import { cn } from '~/lib/utils';

export function SukritSocialRow({ className }: { className?: string }) {
  return (
    <div className={cn('flex flex-row items-center justify-center gap-2', className)}>
      <a href="https://www.facebook.com/sukritwellnesstour/" target="_blank" rel="noreferrer">
        <FacebookSvgBlack />
      </a>
      <a href="https://www.instagram.com/sukritwellnesstours" target="_blank" rel="noreferrer">
        <InstagramSvgBlack />
      </a>
      <a href="https://www.youtube.com/@KumbhSukrit" target="_blank" rel="noreferrer">
        <YouTubeSvgBlack />
      </a>
    </div>
  );
}
