import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cn } from '~/lib/utils';
import './swiper.css';

interface CarouselProps {
  imageUrls: string[];
  className?: string;
  sliderClassName?: string;
}

const CarouselSlide = SwiperSlide;

function Carousel({ imageUrls, className, sliderClassName }: CarouselProps) {
  return (
    <Swiper
      className={cn('h-full w-full', className)}
      modules={[Autoplay, Navigation, Pagination, A11y]}
      speed={2000}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {imageUrls.map((url, index) => (
        <SwiperSlide key={index} className={cn('w-full', sliderClassName)}>
          <img className="h-full w-full object-cover" src={url} alt={`Carousel ${index + 1}`} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export { Carousel, CarouselSlide, type CarouselProps };
