import { Typography } from '~/components/ui/typography';

export interface VanityFeatureProps {
  title: string;
  tagline: string;
  description: string;
}

export function VanityFeature({ title, tagline, description }: VanityFeatureProps) {
  return (
    <div className="flex flex-col items-center justify-center">
      <Typography variant="h1" as="p" className="font-fraunces text-6xl">
        {title}
      </Typography>
      <Typography className="my-2 font-fraunces text-sm uppercase tracking-widest">{tagline}</Typography>
      <Typography className="w-[80%] text-balance leading-snug text-muted-foreground md:mt-3">{description}</Typography>
    </div>
  );
}
