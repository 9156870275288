export function FacebookSvgWhite() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9857 0C13.6448 0 15.2038 0.31466 16.6627 0.943981C18.1216 1.5733 19.3945 2.43147 20.4815 3.51847C21.5685 4.60548 22.4267 5.87366 23.056 7.323C23.6853 8.77235 24 10.3266 24 11.9857C24 13.6448 23.6853 15.2038 23.056 16.6627C22.4267 18.1216 21.5685 19.3945 20.4815 20.4815C19.3945 21.5685 18.1216 22.4267 16.6627 23.056C15.2038 23.6853 13.6448 24 11.9857 24C10.3266 24 8.77235 23.6853 7.323 23.056C5.87366 22.4267 4.60548 21.5685 3.51847 20.4815C2.43147 19.3945 1.5733 18.1216 0.943981 16.6627C0.31466 15.2038 0 13.6448 0 11.9857C0 10.3266 0.31466 8.77235 0.943981 7.323C1.5733 5.87366 2.43147 4.60548 3.51847 3.51847C4.60548 2.43147 5.87366 1.5733 7.323 0.943981C8.77235 0.31466 10.3266 0 11.9857 0ZM16.5054 5.2348H13.9595C13.4636 5.2348 13.006 5.32062 12.5864 5.49225C12.1669 5.66389 11.7998 5.89273 11.4851 6.17878C11.1704 6.46484 10.9273 6.78903 10.7557 7.15137C10.584 7.51371 10.4982 7.87604 10.4982 8.23838V9.75447H8.23838V12.7294H10.4982V18.7366H13.5018V12.7294H15.733V9.75447H13.5018V8.98212C13.5018 8.75328 13.5924 8.57211 13.7735 8.43862C13.9547 8.30513 14.112 8.23838 14.2455 8.23838H16.5054V5.2348Z"
        fill="white"
      />
    </svg>
  );
}
