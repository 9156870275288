import { cn } from '~/lib/utils';

export interface ImageDefinition {
  src: string;
  alt: string;
  className?: string;
}

export interface ImageGridProps {
  columns: ImageDefinition[][];
}

export function ImageGrid({ columns }: ImageGridProps) {
  return (
    <div className="m-auto mt-12 grid w-full grid-cols-1 gap-3 px-8 sm:px-12 md:grid-cols-3 md:px-24">
      {columns.map((column, index) => (
        <ul key={index} className="flex flex-col gap-3">
          {column.map((imgDefinition, key) => (
            <li key={key}>
              <img
                src={imgDefinition.src}
                className={cn(
                  'pointer-events-none max-h-96 w-full select-none rounded-md object-cover md:max-h-[unset]',
                  imgDefinition.className
                )}
                alt={imgDefinition.alt}
              />
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
}
