import { Typography } from '~/components/ui/typography';

export function AsyFeatureCard({
  title,
  description,
  imageSrc,
}: {
  title: string;
  description: string;
  imageSrc: string;
}) {
  return (
    <div className="flex-1">
      <img src={imageSrc} alt={title} className="aspect-[16/11] rounded-lg object-cover" />
      <Typography variant="h4" as="p" className="mt-4 text-center font-fraunces">
        {title}
      </Typography>
      <Typography variant="mutedText" className="text-center font-fraunces italic">
        {description}
      </Typography>
    </div>
  );
}
