import { PlayIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { Typography } from '~/components/ui/typography';
import { YouTubeDialog } from '~/components/ui/youtube-dialog';

export interface TestimonialItem {
  imageSrc: string;
  imageAlt: string;
  quote: string;
  author: {
    name: string;
    location: string;
    videoSrc: string;
  };
}

export function TestimonialGrid({ testimonials }: { testimonials: TestimonialItem[] }) {
  return (
    <div className="m-auto mt-12 grid w-fit grid-cols-1 items-center justify-center gap-x-24 gap-y-20 md:grid-cols-2 xl:gap-x-32">
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index} {...testimonial} />
      ))}
    </div>
  );
}

export function Testimonial({ author, imageAlt, imageSrc, quote }: TestimonialItem) {
  const { t } = useTranslation(['common']);

  return (
    <div className="flex w-fit flex-row items-start gap-3">
      <div className="relative">
        <img
          loading="lazy"
          src={imageSrc}
          alt={imageAlt}
          className="pointer-events-none mt-2 h-16 min-h-16 w-16 min-w-16 select-none rounded-full object-cover"
        />
        <div className="absolute bottom-0 right-0 font-mono text-xs">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <YouTubeDialog embedUrl={author.videoSrc}>
                  <div className="cursor-pointer rounded-full bg-black p-[2px]">
                    <PlayIcon color="black" fill="white" size={16} />
                  </div>
                </YouTubeDialog>
              </TooltipTrigger>
              <TooltipContent>{t('common:testimonial-grid.view-testimonial')}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="flex w-fit flex-col">
        <p className="text-6xl leading-[0] text-[#98E021]">“</p>
        <Typography className="mt-2 max-w-[480px] leading-snug">{quote}</Typography>
        <Typography variant="smallText" className="mt-3 font-fraunces text-xs font-bold uppercase tracking-widest">
          {author.name}, {author.location}
        </Typography>
        <YouTubeDialog embedUrl={author.videoSrc}>
          <Typography
            variant="smallText"
            className="mt-1 cursor-pointer font-fraunces font-light text-muted-foreground underline"
          >
            {t('common:testimonial-grid.view-testimonial')}
            {' >'}
          </Typography>
        </YouTubeDialog>
      </div>
    </div>
  );
}
