import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '~/lib/utils';

const typographyVariants = cva('font-sans', {
  variants: {
    variant: {
      h1: 'scroll-m-20 font-serif text-3xl font-normal xl:text-4xl',
      h2: 'scroll-m-20 font-serif text-2xl font-normal xl:text-3xl',
      h3: 'scroll-m-20 font-fraunces text-xl font-normal xl:text-2xl',
      h4: 'scroll-m-20 font-sans text-lg font-normal xl:text-xl',
      h5: 'scroll-m-20 font-fraunces text-lg font-normal',
      h6: 'scroll-m-20 font-fraunces text-base font-normal',
      p: 'leading-7',
      tagline: 'font-fraunces text-sm font-light uppercase tracking-widest',
      blockquote: 'mt-6 border-l-2 pl-6 italic',
      ul: 'ml-6 list-disc [&>li]:mt-2',
      ol: 'ml-6 list-decimal [&>li]:mt-2',
      inlineCode: 'relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold',
      lead: 'text-xl text-muted-foreground',
      largeText: 'text-lg font-semibold',
      smallText: 'text-sm font-medium leading-none',
      mutedText: 'text-muted-foreground',
    },
  },
  defaultVariants: {
    variant: 'p',
  },
});

type VariantPropType = VariantProps<typeof typographyVariants>;

const variantElementMap: Record<NonNullable<VariantPropType['variant']>, string> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  tagline: 'p',
  blockquote: 'blockquote',
  inlineCode: 'code',
  largeText: 'div',
  smallText: 'small',
  lead: 'p',
  mutedText: 'p',
  ul: 'ul',
  ol: 'ol',
};

export interface TypographyProps extends React.HTMLAttributes<HTMLElement>, VariantProps<typeof typographyVariants> {
  asChild?: boolean;
  as?: string;
}

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  ({ className, variant, as, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : as ?? (variant ? variantElementMap[variant] : 'p') ?? 'div';
    return <Comp className={cn(typographyVariants({ variant, className }))} ref={ref} {...props} />;
  }
);

Typography.displayName = 'Typography';

export function Emphasize({ children }: { children: React.ReactNode }) {
  return (
    <u>
      <em>
        <strong>{children}</strong>
      </em>
    </u>
  );
}

export function DashedText({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={cn('flex w-full items-center', className)}>
      <div className="flex-grow border-t border-dashed border-gray-400"></div>
      {children}
      <div className="flex-grow border-t border-dashed border-gray-400"></div>
    </div>
  );
}
